
// Body
$body-bg: #f8fafc;

// Typography
//$font-family-sans-serif: "Futura", sans-serif;
$font-family-sans-serif: "Flama", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$fa-font-path: '../webfonts';



// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;



$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;


$blue: #3490dc;
$indigo: #6574cd;
$purple: #722778; // cap-purple: 722778;
//$purple: #e2017d;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$lightgrey: #ededed;
$white: #ffffff;
$gray: #999999;
$dark-gray: #343434;

// Colors
$text-color: #212529;
$text-color-light: #777;

$cap-purple: #722778; // cap-purple: 722778;
$cap-purple-haze: rgba(114 , 39, 120, 0.8);
$cap-gray: #999999;
$cap-dark-gray: #7e7e7e;
$cap-light-gray: #c2c2c2;

$tangit-pink: #d52877;
$sista-green: #009d98;
$ponal-brown: #7a6558;

$text-xxxxl: 3rem;
$text-xxxl: 2rem;
$text-xxl: 1.7rem;
$text-xl: 1.4rem;
$text-l: 1.2rem;
$text-m: 1.0rem;
$text-s: 0.8rem;
$text-xs: 0.7rem;
$text-xxs: 0.5rem;
$text-xxxs: 0.3rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1240px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1210px
);
