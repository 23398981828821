#kontakt-form {

  width:100%;

  textarea {
    height: 518px;
  }

  input, textarea {
     background: #e8e8e8;
     box-shadow: none;
     border: 0;
   }

  .field-wrap label{
    font-size:24px;
    font-weight:700;
  }

  label{
    font-size: 18px;
    font-weight: 400;
    display: inline-block;
  }

  label a{
    text-decoration: underline;
    color: #575757;
  }

  input{
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: .5rem;
    border-radius: 0;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    color: #0a0a0a;
    transition: box-shadow .5s,border-color .25s ease-in-out;
    -webkit-appearance: none;
  }

  input[type=checkbox]{
    display: none;
  }

  input[type=checkbox]+label::before{
    background-color: #e8e8e8;
    border: 2px solid #e8e8e8;
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: -4px 0 0 0;
    vertical-align: middle;
    content: "";
    cursor: pointer;
    color: #000;
  }

  input[type=checkbox]:checked+label::before {
    content: "";
    background-color: #7a6558;
  }

}





