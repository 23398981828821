// Fonts
@font-face {
    font-family: 'Flama';
    font-style: normal;
    font-weight: normal;
    src: local('Flama'), url('public/webfonts/Flama Regular.woff') format('woff');
    //src: local('Flama'), url('../../public/fonts/Flama Regular.woff') format('woff');
}
@font-face {
    font-family: 'Flama Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Flama Italic'), url('public/webfonts/Flama Italic.woff') format('woff');
    //src: local('Flama Italic'), url('../../public/fonts/Flama Italic.woff') format('woff');
}
@font-face {
    font-family: 'Flama Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Flama Bold Italic'), url('public/webfonts/Flama Bold Italic.woff') format('woff');
    //src: local('Flama Bold Italic'), url('../../public/fonts/Flama Bold Italic.woff') format('woff');
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';

//Carousel Slider
@import '~owl.carousel/dist/assets/owl.carousel.css';

@import 'nav';
@import 'contact';
@import 'service';
@import 'products';
@import 'downloads';

html {
    //height: 100%;
    position: relative;
}

body {
    //height: 100%;
    background-color: #e1e1e1;
    min-width: 320px;
    font-size: 18px;
}

#app{
    max-width: 1500px;
    margin: 0 auto;
    background: #fff;
}

p {
    //font-size: 18px !important;
    font-size: 18px;
}
p:last-child {
    margin: 0;
}
.lead {
    //font-size: 125%;
    //line-height: 1.6;
    font-weight: 400;
}
h6 {
    font-size: 1rem;
    font-weight: 700;
}
figure figcaption {
    color: #c6c6c5;
    font-size: 16px;
}

/*.row a img {
    transition: opacity 0.2s;
    &:hover {
        opacity: 0.7;
    }
}*/
/*
p.lead1{
    font-size: 125% !important;
    line-height: 1.6 !important;
}*/
a, a:focus:not(.button-modal), a:hover {
    color: $ponal-brown;
    //transition: color .5s ease,background .5s ease,opacify .5s ease;
    cursor: pointer;
}
.dropdown-item {
    transition: color .5s ease,background .5s ease,opacify .5s ease;
}
.dropdown-item a {
    background-color: transparent;
}
@media print, screen and (min-width: 63.75em){
    h3 {
        font-size: 26px !important;
        padding: 0 0 10px 0;
        a{
            color: #000;
            &:hover{
                color: #575757;
            }
        }
    }

    h2.adviser{
        font-size: 26px !important;
        font-weight: 700;
        line-height: 1.2;
        padding: 0 0 5px 0;
    }
}

@media print, screen and (min-width: 63.75em){
    h5 {
        font-size: 20px;
        line-height: 1.5;
    }
}

@media print, screen and (min-width: 40em){
    h5 {
        /*font-size: 19px;
        line-height: 1.4;*/
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 5px;
    }
}

h5{
    font-weight: 700;
}

@media print, screen and (min-width: 40em){
    h3 {
        font-size: 24px;
        padding: 0 0 8px 0;
        a{
            color: #000;
            &:hover{
                color: #575757;
            }
        }
    }
}

h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    padding: 0 0 5px 0;
    a{
        color: #000;
        &:hover{
            color: #575757;
        }
    }
}

h2{
    font-size: 24px;
    line-height:1.1;
    font-weight: 700;
}

h1 {
    font-size: 30px;
    line-height: 1.15;
    padding: 0 0 10px 0;
    font-weight: 700;
}

@media print, screen and (min-width: 63.75em){
    h2 {
        font-size: 34px;
    }
}

@media print, screen and (min-width: 40em){
    h1 {
        font-size: 40px;
        padding: 0 0 15px 0;
    }
}
@media print, screen and (min-width: 63.75em){
    h1 {
        font-size: 40px;
        padding: 0 0 20px 0;
    }
}



//.container{
//  width: 100%;
//  min-width: 320px;
//  max-width: 1270px;
//  margin: 0 auto;
//  padding: 0 10px;
//  box-sizing: border-box;
//  position: relative;
//}

.container{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media (min-width: 1280px){
    .container {
        max-width: 1200px;
    }
}



textarea{
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: .5rem;
    border: 4px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    color: #0a0a0a;
    transition: box-shadow .5s,border-color .25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

header {
    background: #fff;

    form{
        margin: 42px 0 29px 0;
        //margin: 34px 0 29px 0;

        input[type=text]{
            width: calc(100% - 45px);
            margin-right: 5px;
            display: inline-block;
            box-shadow: none;
            height: 30px;
            background: #e9e9e9;
            border: 1px solid #cacaca;
            border-radius: 0;
            padding:.5rem;
            color: #0a0a0a;
            font-weight: 400;
            transition: box-shadow .5s,border-color .25s ease-in-out;
            -webkit-appearance: none;
        }
    }

    ul#top-menu{
        padding: 0;
        margin: 0;
        margin-top: 43px;
        cursor: default;
        list-style: none;
        display: table;
        width: 100%;
        empty-cells: show;
        white-space: nowrap;
        text-align: right;
        li{
            color: #575756;
            font-size: 14px;
            padding: 0;
            margin: 0;
            //display: table-cell;
            display: inline;
            text-align: center;
            vertical-align: middle;
            font-weight: 700;
            //&:not(:last-child) {
                padding-right: 0.5rem;
            //}

            i.far{
                padding-right: 7px;
            }

            a{
                color: #575756;
                &:hover{
                    text-decoration: underline;
                }
            }
        }

    }
    #logo {
        float: left;
    }
    #logo img {
        width: 80px;
        margin-top: 10px;
        @media (min-width: 992px) {
            width: 130px;
            margin-top: 17px;
        }
    }

    .container {
        //padding-top: 18px;
        padding-bottom: 18px;
        position: relative;
    }

    @media (max-width: 767px) {

        .container {
            text-align: center;
        }
    }

}
@media (min-width: 768px) {
    #accordion-product {
        display: none;
    }
}
@media (max-width: 767px) {
    #tabs {
        display: none;
    }
}
.copyright {
    color: #000;
    background-color: $white;
    padding: 30px 0;
    font-size: 12px;

    a{
        white-space: nowrap;
        color: $ponal-brown !important;
        &:hover{
            text-decoration: underline;
        }
    }
}

.middle{
    font-size: 1.35rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}


footer {
    //background-color: #F5F5F5;
    background-color: #343434;
    color: #fff;
    padding:30px 0 30px 0;

    .logo {
        img {
            max-width: 100px;
            height: auto;
        }
    }

    ul{
        li{
            a{
                color: #ffffff !important;
                &:hover{
                    text-decoration: underline;
                }
            }
            &:last-child{
                padding-bottom: 35px;
            }
        }
    }

    a{
        color: #000;
        text-decoration: none;
        &:hover{
            color: #000;
            text-decoration: underline;
        }
    }

    a.underline{
        display: block !important;
        border-bottom: 2px solid $white;
        text-decoration: none;
        color: $white;
    }

    p{
        padding:10px 0 20px 0;
    }

    .title {
        color: $white;
        font-size: 1.2rem;
    }

    .links {
        padding: 60px 0;
        color: $text-color-light;

        h4 {
            font-size: 1rem;
            font-weight: $font-weight-semi-bold;
            text-transform: uppercase;
            color: $white;
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                line-height: 1.6rem;

                a {
                    text-decoration: none;
                    color: $text-color-light;
                    padding-bottom: 7px;
                    transition: 0.2s all ease;

                    &:hover {
                        color: $white;
                        cursor: default;
                    }
                }
            }
        }
    }


    ul{
        padding: 0;
        margin: 0;
        cursor: default;
        list-style: none;


        li{
            padding-bottom: 10px;
            font-size: 18px;
        }
    }

    .text-underline{
        text-decoration: underline;
    }


    @media (max-width: 767px) {

        .logo {
            padding-bottom: 18px;
        }

        .links {
            padding-top: 0;
        }

        h4 {
            text-transform: uppercase;
            color: $text-color!important;
            font-size: 1rem;
            font-weight: $font-weight-semi-bold;
            cursor: pointer;
            margin-bottom: 8px;
            transition: 0.2s all ease;

            &:hover {
                color: $ponal-brown!important;
            }

            //&:after {
            //  position: absolute;
            //  color: $text-color;
            //  right: 14px;
            //  font-family: "Font Awesome 5 Free";
            //  content: "\f068";
            //}

            &.collapsed {
                &:after {
                    position: absolute;
                    color: $text-color;
                    right: 14px;
                    font-family: "Font Awesome 5 Free";
                    content: "\f067";
                }
            }
        }

        p {
            color: $text-color-light;
        }

        .footer-mobile-wrapper {
            padding: 16px 0 8px;
            border-bottom: 1px solid $lightgrey;

            &.first {
                margin-top: 20px;
                border-top: 1px solid $lightgrey;
            }
        }

        .copyright {
            text-align: center;
        }

    }


    @media (min-width: 768px) {
        padding: 60px 0 0;

        .services {
            border-bottom: 1px solid $gray;
            padding-bottom: 40px;

            i {
                font-size: 2.2rem;
                color: $ponal-brown;
                position: absolute;
            }

            .title {
                margin-left: 50px;
            }

            p {
                margin-left: 50px;
                color: $text-color-light;
            }
        }

        .collapse.dont-collapse-md {
            display: block;
            height: auto !important;
            visibility: visible;
        }

    }

}


#search+.fa-search{
    position: relative;
    top:5px;
    font-size: 1.5em;
}

@media (max-width: 991px) {
    i.fa-search{
        //color: black !important;
        &:hover{
            //color: $ponal-brown !important;
        }
    }
    .modal-dialog {
        padding: 30px;
    }
}
@media (max-width: 991px) {
    .produkt-index-btn {
        width: 100%;
    }
    .col-sm-12 {
        padding-bottom: 15px;
        padding-top: 15px;
    }
}
.button {
    font-size: 18px;
    font-weight: 700;
    //padding: 15px 35px;
    padding: 15px;
    //background-color: #343434;
    background-color: $ponal-brown;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color .25s ease-out,color .25s ease-out;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    color: #fefefe;
}

.button:focus:not(.button-modal), .button:hover {
    text-decoration: none;
    //background-color: #575757;
    background-color: #5b4c43;
    color: #fefefe;
    outline:0;
}

button:focus, button:active, button:visited{
    outline:0;
}

div.space{
    width:100%;
    height:27px;
}

div.space2{
    width:100%;
    height:54px;
}

.hr_table{
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    border-bottom: 1px solid black;
}

.td_width{
    //width:70% !important;
}

.row-name{
    width:60%;
}

.row-more{
    width:40%;
}

.row-title{
    width:60%;
}

.row-size{
    width:15%;
}

.row-type{
    width: 5%
}

.row-space{
    width:20%;
}





.search-result:first-child {
    border-top: 1px solid #343434;
}


.search-result {
    border-bottom: 1px solid #343434;
    padding: 30px 0;
    div {
        font-size: 18px;
    }
}

.search-result .text-center {
    font-size: 28px;
    font-weight: 700;
}

.search-result a {
    color: black;
    display: block;
    padding-bottom: 8px;
    text-decoration: none;
    transition: 0s;
    padding-top: 3px;
    font-size:28px !important;
}
.search-result a:hover {
    color: $ponal-brown;
    div {
        color: $ponal-brown;
    }
}

.search-result-title{
    font-size: 24px !important;
    font-weight: 700;
}
.search-result-url {
    font-size: 16px !important;
    //color: $ponal-brown;
    padding-bottom: 10px;
}




//POPUP
iframe{
    width:100%;
    //height:600px;
}

a#modal{
    cursor: pointer !important;
}

.modal-header{
    border-bottom: 0;
    display:block;

    h2{
        padding: 20px;
    }
}

.modal-content{
    //padding:20px;
    border-radius: 0;
}

.modal-body{
    padding: 0 40px 0 40px;
}

.modal-footer{
    //display: none;
}

button.close{
    background-color: $ponal-brown;
    opacity: 0.9 !important;
}
button.close:hover {
    opacity: 1 !important;
}
.close{
    color: white !important;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 860px;
        margin: 0 auto;
    }

}






//BREADCRUMBS
.breadcrumb {
    padding: 0;
    cursor: default;
    list-style: none;
    margin: 9px 0;
    background-color: transparent;
}

.breadcrumb::after, .breadcrumb::before {
    display: table;
    content: ' ';
}

.breadcrumb li:first-child {
    padding: 2px 10px 2px 10px;
}

.breadcrumb .gray li {
    background: #e8e8e8;
}
.breadcrumb li:hover {
    background: #343434 !important;
    color: #fff;
}

.breadcrumb.gray li:not(:last-child)::before {
    border-left-color: #fff;
}

.breadcrumb li:hover a {
    color: #fff;
    text-decoration: none;
}

.breadcrumb li:hover:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: -11px;
    display: block;
    width: 1px;
    height: 26px;
    border: 13px solid transparent;
    border-left: 13px solid #343434 !important;
    border-right: 0;
    margin: 0;
    z-index: 10;
}

.breadcrumb li {
    color: #343434 !important;
    background: #e8e8e8 !important;
    text-transform: none;
    font-size: 14px !important;
    position: relative !important;
    padding: 2px 10px 2px 20px !important;
}

.breadcrumb.gray li:not(:last-child)::before {
    border-left-color: #fff;
}

.breadcrumb li:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 0;
    right: -13px;
    display: block;
    width: 1px !important;
    height: 26px;
    border: 13px solid transparent;
    border-right: 0;
    border-left: 13px solid #e8e8e8;
    z-index: 10;
}

.breadcrumb li a {
    color: #343434;
}

.breadcrumb li:not(:last-child)::after {
    border-left-color: #e8e8e8 !important;
}

.breadcrumb li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: -11px;
    display: block;
    width: 1px !important;
    height: 26px;
    border: 13px solid transparent;
    border-left: 13px solid #fff;
    border-right: 0;
    margin: 0;
    z-index: 10;
}

.breadcrumb li:last-child {
    background: #343434 !important;
    color: #fff !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "";
    padding-right: 0 !important;
}

.breadcrumb-item{
    a{
        text-decoration: none !important;
    }
}

.row + .row{
    padding-top: 25px;
}



#return-to-top {
    position: fixed;
    bottom: 40%;
    right: 0px;
    background: $ponal-brown;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 5000;
}
#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: #575757;
}
#return-to-top:hover i {
    color: #fff;
}

@media screen and (max-width: 63.6875em){
    a#info, a#up, div#info-menu {
        display: none;
    }
}

@media print, screen and (min-width: 63.75em) {
    footer {
        padding: 40px 0 40px 0;
    }
}

.stripes{
    background-color: #F4F4F4;

    &.margin-big{
        margin:30px 0;
    }

    &.padding-big{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.padding-big{
    padding-top: 60px;
    padding-bottom: 60px;
}
.main.padding-big {
    padding-top: 30px;
    padding-bottom: 30px;
}
button.button-black{
    background-color: $dark-gray;
    padding:10px 23px 8px 18px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    color: $white;
    text-decoration: none;
    border: none;
    cursor: pointer;

    &:hover{
        background-color: #575757;
    }

}

a.button-black{
    background-color: $dark-gray;
    padding:10px 23px 8px 18px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    color: $white;
    text-decoration: none;
    border: none;
    cursor: pointer;

    &:hover{
        background-color: #575757;
    }

}









.slider .orbit-caption {
    background: 0 0;
    bottom: 35%;
    padding: 0 100px;
}

.orbit-caption {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    padding: 1rem;
    background-color: rgba(10,10,10,.5);
    color: #fefefe;
}

.pointer-button{
    cursor: pointer !important;
    color: $white;
    border:none;
    background: transparent;
    font-size: 16px;
    font-weight: 700;
    padding:0;
    white-space: normal;
    word-wrap: break-word;
}

footer{
    .pointer-button{
        font-size: 18px;
        font-weight: 200;
        &:hover{
            text-decoration: underline;
        }
    }
}

.button-search{
    border:0;
    background-color: transparent;
    cursor: pointer;
    @media (max-width: 991px) {
        &:hover{
            color: $ponal-brown;
        }
    }
}



.owl-carousel{
    //padding-bottom: 40px;
}
.owl-carousel.owl-loaded {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}
.owl-nav, .owl-dots {
    //height: 0;
    text-align: center;
}
.product_details .owl-nav button div.nav-btn {
    color: black;
    opacity: 0.5;
}
.owl-dot span {
    background: none repeat scroll 0 0 #869791;
    background-color: rgb(134, 151, 145);
    border-radius: 20px;
    display: block;
    height: 12px;
    margin: 5px 7px;
    opacity: 0.5;
    width: 12px;
}
.owl-dot.active span {
    background-color: black;
}
.welcome{
    padding-bottom: 30px;
    padding-top: 30px;
}

.bottom{
    padding-bottom: 30px;
    padding-top: 30px;
}

.padding{
    padding-top: 40px;
    //padding-bottom: 40px;
}

a:not([href]):not([tabindex]) {
    //color: $white;
    color: $ponal-brown;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    //color: $white;
    color: $ponal-brown;
    text-decoration: none;
}



#title {
    background-color: #000;
    padding: 40px 0px;
    .fa {
        color:#fff;
        font-size:40px;
        padding-left:40px;
    }
}

.kooperation_img{
    padding-right: 20px;
}



/* Ow; Slider CSS*/

.owl-wrapper {
    positon: relative;
}

.owl-controls {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.owl-theme .owl-controls .owl-page span {
    background: #fff !important;
}

.owl-img {
    width: 100%;
}

.owl-text-overlay {
    position: absolute;
    //width: 15%;
    top: 38%;
    //transform: translateY(-50%);
    left: 11%;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    padding: 30px;
    float: left;
}

.owl-text-overlay-home {
    position: absolute;
    //width: 15%;
    top: 38%;
    //transform: translateY(-50%);
    left: 10%;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    padding: 30px;
    float: left;
}

h2.owl-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

h2.adviser{
    font-size: 20px;
}

p.owl-caption {
    font-size: 18px;
    line-height: 24px;
}

.owl-theme .owl-controls .owl-page span:active {
    background: #fff !important;
}

@media screen and (max-width:991px){
    h2.owl-title {
        font-size: 30px;
        font-weight: bold;
    }
}


@media screen and (max-width:768px){
    h2.owl-title {
        font-size: 20px;
        font-weight: bold;
    }
}

@media screen and (max-width:587px){
    h2.owl-title {
        display: none;
    }

    .owl-text-overlay{
        display: none;
    }

    .owl-text-overlay-home{
        display: none;
    }
}

/* hide previous and next */

.owl-buttons {
    visibility: hidden;
    display: none;
}




#cookiesDisclaimer-wrapper *
{
    //font-size: 12px;
    box-sizing:border-box;
    text-align:left;
    color: #000;
}

.cookienote_details{
    margin-bottom: 17px;
    font-size: 14px;
    //font-family: "Futura",Arial,sans-serif;
    font-family: "FlamaBold",Arial,sans-serif;
    font-weight: 900;
    line-height: 14px;
    text-decoration: none !important;
    color: $ponal-brown !important;
}

#cookiesDisclaimer-wrapper
{
    width:100%;
    position:fixed;
    top:0;
    left:0;
    z-index: 10000;
    font-size:10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    color:#9b9b9b;
    padding: 15px 20px 20px;
    border: 1px solid #5f6973;
    background-color: rgba(255,255,255,0.95);

}

.cookienote__container{
    //margin-left: auto;
    //margin-right: auto;
    //padding: 0 10px;
    //width: 100%;
    position:relative;
}


#btnControl
{
    display: none;
}

#more{
    cursor:pointer;
    display:block;
    color: $ponal-brown;
    margin-top:10px !important;
    margin-bottom:10px !important;
}

#more:hover
{
    color: $ponal-brown;
}

#cookies_more
{
    overflow:hidden;
    padding:0;
    padding-left:10px !important;
    width: calc(100% - 25px) !important;
    height:0;
    transition: 1s;
    margin-bottom:0 !important;
    font-style: italic;
    font-size:12px;
    color: #9b9b9b;
}

#cookies_more a
{
    color: $ponal-brown;
}

#btnControl:checked + label > div
{
    padding:10px !important;
    width: calc(100% - 25px) !important;
    height:auto;
    border:1px solid $ponal-brown;
    margin-bottom:20px !important;
    background: #fff;
}


.cookies_hint{
    font-size: 16px;
    //font-family: "Futura",Arial,sans-serif;
    font-family: "FlamaRegular",Arial,sans-serif;
    font-weight: 100;
    line-height: 21px;
    margin-bottom: 17px;
}

#cookiesHeader
{
    font-size:24px;
    font-weight:700;
    line-height: 27px;
    margin-bottom:20px !important;;
    margin-top:10px !important;
    //color: $ponal-brown;
    color: black;
}

.cookiesDisclaimer-button
{
    text-decoration: none;
    display:inline-block;
    padding:13px 15px 14px !important;
    float:left;
    font-size: 14px;
    line-height: 14px;
    margin-right: 25px !important;
    //background: $ponal-brown;
    background: black;
    color: #fff !important;
    margin-top: 17px;
    border: 3px solid #ffffff;
}

.cookiesDisclaimer-button:hover
{
    background: white;
    color: black !important;
    border: 3px solid #000;
}

.cookienote__closeButton {
    font-size: 16px;
    //font-family: "Futura",Arial,sans-serif;
    font-family: "FlamaRegular",Arial,sans-serif;
    font-weight: 100;
    line-height: 21px;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 27px;
    background: none;
    margin: 0;

    &:hover{
        background: none;
    }
}

.button.cookienote__closeButton{
    transition: none !important;
    -webkit-transition: none !important;
    color: #5f6973 !important;
    &:focus{
        background-color: transparent !important;
    }
    &:hover{
        text-decoration: none !important;
    }
}

.cookienote__closeLabel {
    display: inline-block;
    vertical-align: middle;
    font-weight:900;
    line-height:14px !important;
    font-size: 14px;
    text-decoration: none !important;
    color: #5f6973 !important;
    &:hover{
        text-decoration: none !important;
    }
    a:hover{
        text-decoration: none !important;
    }
    a{
        color: #5f6973 !important;
    }
}

.button .heli__svg-icons {
    margin: 0px 0px -10px 7px;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    pointer-events: none;

    i{
        color: #5f6973 !important;
    }
}


#cookiesDisclaimer-wrapper .fa-external-link
{
    font-size:8px;
    /*position:absolute;*/
    margin-top:8px;
    margin-left:3px;
}

.fa-external-link-alt:before {
    padding-left: 10px;
}

span.updown
{
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}


span.updown:before
{
    //content: "\f078";
    font-size:8px;
    position:absolute;
    margin-top:-8px;
    margin-left:-1px;
}

#btnControl:checked + span + label span.updown:before
{
    //content: "\f077";
}

@media screen and (max-width: 63.6875em){
    .copyright .col-sm-12 {
        text-align: left!important;
    }
}

#sprung{
    z-index: 100;
}

#sprungmarkenmenue {
    font-size: 33px;
    font-weight: 600;
    padding: 10px !important;
    background-color: #f2f2f2 !important;
}

#sprungmarke{
    li{
        list-style: none !important;
        a{
            color: #717879 !important;
        }
    }
}

#suggestbox_1{
    background-color: #707879;
    color: #ffffff;
    height: 200px;
    margin-left: 2%;
}

#suggestbox_2{
    background-color: #375667;
    color: #ffffff;
    height: 200px;
    margin-left: 2%;
}

#suggestbox_3{
    background-color: #DADADA;
    color: #717879;
    height: 200px;
    margin-left: 2%;
    a{
        color: #717879 !important;
    }
}

.ish-sc-element{
    margin-top: 20%;
}

.underline1{
    text-decoration: underline !important;
}

.suggest{
    background: #000;

    img{
        opacity:0.4;
    }
}

.image-container {
    position: relative;
}
.image-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    background: rgba(0, 0, 0, .4);
    cursor: pointer;
}
.image-container:hover .after {
    //display: block;
    transition: .9s;
    background: rgba(0, 0, 0, .6);
}
.event {
    background: rgba(0,0,0,.8);
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    clear: both;
    color: #fff;
    position: relative;
    div.year {
        color: $ponal-brown;
        font-size: 80px;
        text-align: center;
    }
}
@media screen and (min-width: 63.75em){
    .event {
        width: calc(50% - 25px);
        div.year {
            position: absolute;
            top: 50%;
            margin-top: -40px;
        }
        &.left {
            float:left;
            div.year {
                right: -230px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -45px;
                width: 13px;
                height: 40px;
                border: 21px solid transparent;
                border-left-color: transparent;
                border-left-style: solid;
                border-left-width: 21px;
                border-left: 25px solid rgba(0,0,0,.8);
            }
        }
        &.right {
            float: right;
            div.year {
                left: -230px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -45px;
                width: 13px;
                height: 40px;
                border: 21px solid transparent;
                border-right-color: transparent;
                border-right-style: solid;
                border-right-width: 21px;
                border-right: 25px solid rgba(0,0,0,.8);
            }
        }
    }
}
#accordion, #accordion-product {
    .accordion-title {
        padding: 0;
        background-color: transparent;
    }
    .btn {
        background-color: #343434;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        width: 100%;
        box-shadow: none;
        text-align: left;
        text-decoration: none;
        padding: 1.5rem;
        transition: 0.2s all ease;
        &.collapsed {
            background-color: transparent;
            color: #212529;
        }
    }
    .btn:hover {
        background-color: #343434;
        color: #fff;
    }
}
#accordion, #accordion-product {
    .card {
        border: 0;
        background-color: transparent;

        .btn {
            border: 0;
            border-radius: 0;
            border-top: 1px solid;
        }
        &:last-child {
            .btn {
                border-bottom: 1px solid;
            }
        }
    }
}
button.kreativ-title__go-to-link {
    text-decoration: none;
    background-color: transparent;
    border: 0;
}
.form-control.is-invalid {
    border: 1px solid #e3342f !important;
}
.invalid-feedback {
    display: block;
}

.tx_ethenkel {
    margin-bottom: 2rem;

    #form {
        display: flex;
        flex-direction: column;
        min-height: 355px;
        padding: 2.5rem;
        margin: 0;
        background-color: #e5e6e6;

        .row {
            margin: 0;

            .column {
                flex: 1;
            }
        }
        input, label, select {
            width: 100%;
            font-size: 18px;
        }
        input, select {
            padding: .5rem;
            border: 1px solid #cacaca;
            border-radius: 0;
            background-color: #fefefe;
            box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
            font-family: inherit;
            font-weight: 400;
            color: #0a0a0a;
            transition: box-shadow .5s,border-color .25s ease-in-out;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 2.4375rem;
            margin: 0 0 1rem;
        }
        input {
            display: block;
            box-sizing: border-box;
        }
        label {
            margin-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: bold;
        }
        select {
            padding: .5rem;
            padding-right: 35px;
            background-image: url('../images/select_box_icon.jpg');
            background-repeat: no-repeat;
            background-position-y: top;
            background-position-x: calc(100%);
            background-size: contain;
        }
        .button-transparent {
            background-color: transparent;
        }
        .reset-button {
            color: $ponal-brown;
            text-decoration: underline;
            font-weight: lighter;
        }
        .is-invalid {
            border: 1px solid red !important;
        }
    }
    .olMapViewport {
        z-index: 0;
    }
    #map {
        width: 100%;
        height: 100%;

        p {
            font-size: 16px !important;
        }
    }

    #plz-container, #listen-container {
        width:100%;
    }
    #far-listen-container {
        display:none;
    }
    .olPopup, .olFramedCloudPopupContent {
        white-space: nowrap;
    }
    div.far-haendler, div.haendler, div#more-haendler {
        margin-top: 0.5rem;
    }
    strong {
        font-weight: 400;
    }
    ul {
        list-style-type: none;
        padding: 0;
        &.koordinaten-liste {
            li {
                a {
                    display: flex;
                    align-items: center;
                    padding: 1.5rem 0;
                    border-top: 1px solid black;
                    color: #212529;
                    transition: 0s !important;
                    span {
                        line-height: 1.5rem;
                        flex: 1;
                        text-align: center;
                        &:nth-child(2) {
                            flex: 5;
                            text-align: left;
                        }
                        h3 {
                            font-size: 19px !important;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

ul.product-details-variants {
    padding-left: 1rem;

    li {
        list-style-position: inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.square_wrapper_container.product_details {
    .owl-product-image {
        height: 100%;
    }
    .owl-stage-outer {
        flex: 1;
        height: 100%;
        .owl-stage {
            //display: flex;
            //max-height: 100%;
            height: 100%;

            .owl-item {
                display: flex;
                height: 100%;
            }
        }
    }
}
.marker_flex {
    display: flex;
}
.marker_name, .marker_text {
    flex: 1;
}
.marker_name {
    max-width: 75%;
    white-space: normal;
}
.marker_brand_image {
    margin: 0 1rem;
}
.marker_number {
    //padding-bottom: 0.5rem;
}
.marker_text i {
    font-size: 1rem;
    padding-right: 1rem;
}

body.epp-overflow {
    overflow: hidden;
}
.epp-wrapper-blur {
    filter: blur(5px);
}
.epp-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.6);
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
}
.epp-modal-wrapper {
    padding: 20px;
    position: absolute;
    width: 80%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    overflow-x: hidden;
    transform: translate(-50%,-50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.epp-modal-header {
    display: flex;
    margin-bottom: 10px;
}
.epp-modal-description div {
    margin-bottom: 20px;
    span {
        font-weight: 100;
        font-size: 16px;
        line-height: 22px;
    }
    button {
        display: none;
    }
}
.epp-modal-content {
    margin-bottom: 20px;
    .epp-content-checkbox {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        background-color: #f7f7f5;
        padding: 10px 15px 10px;
    }
    label {
        margin: 0;
        font-size: .875rem;
        #checkbox-essentials {
            margin-right: .5rem;
            //cursor: not-allowed;
        }
    }
    .epp-content-toggle {
        cursor: pointer;
        width: 100%;
        p {
            margin: 0;
            float: left;
            font-weight: 100;
            font-size: 16px;
            line-height: 1.2;
            color: #7d7d7d;
        }
        .epp-cookie-desc-toggle {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            top: 0;
            background-color: #ccc;
            float: right;
            &::after {
                content: '\f078';
                position: absolute;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 10px;
                color: white;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
        }
    }
}
.epp-content-cookie-desc {
    background: #f7f7f5;
    &.epp-show {
        opacity: 1 !important;
        display: block !important;
        transition: opacity 0.4s ease;
    }
    p {
        padding: 15px;
        margin: 0;
        font-weight: 100;
        font-size: 15px;
        line-height: 22px;
        color: #7d7d7d;
    }
    .epp-cookie-details {
        position: relative;
        min-height: 30px;
        bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
        color: white;
        text-align: center;
        cursor: pointer;
        padding: 0px 10px;
        .epp-detail-titel {
            display: block;
            background-color: #cecece;
            padding: 7px;
            font-weight: 100;
            font-size: 12px;
            line-height: 22px;
        }
    }
}
.epp-modal-buttons div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    button {
        color: white;
        background: $ponal-brown;
        font-weight: bold;
        line-height: 1;
        cursor: pointer;
        border: none;
        padding: 14px;
    }
    span {
        display: none;
    }
}
.epp-modal-info {
    display: flex;
    justify-content: center;
    a {
        text-decoration: none;
        font-weight: 100;
        font-size: 12px;
        line-height: 1;
        margin: 0 5px;
    }
}
.cookie-desc-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 20px 10px;
    text-align: center;
    background-color: white;
    .cookie-desc-exit {
        cursor: pointer;
        color: white;
        position: relative;
        left: 10px;
        top: 5px;
    }
    .epp-cookie-essentials {
        padding: 20px;
        margin-bottom: 10px;
        background: $ponal-brown;
        p, span {
            color: white;
            font-weight: 100;
            font-size: 16px;
            line-height: 22px;
        }
        span {
            font-weight: bold;
        }
    }
}
.container-wissen {
    p {
        margin-bottom: 20px;
    }
}
.modal-newsletter {
    height: 100%;
    display: flex;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    .modal-content {
        height: 100%;
        overflow: scroll;
        display: flex;
        .modal-body {
            display: flex;
            flex: 1;
            overflow: scroll;
            .newsletter-panel {
                display: block;
                iframe {
                    height: 100%;
                    html {
                        height: 100%;
                        body {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
.download-select+label::before {
    background-color: #e8e8e8;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    content: "";
    cursor: pointer;
    color: #000;
}
.download-select+label.disabled::before {
    background-color: transparent;
}
.download-select:checked+label::before {
    content: "";
    background-color: $ponal-brown;
}

.video-thumbnail {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:before {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        content: "\f144";
        font-family: "Font Awesome 5 Free";
        font-size: 100px;
        color: #fff;
        opacity: .8;
        text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    }
    &:hover:before {
        color: #eee;
    }
}

.video-wrapper {
    padding: 15px 0;

    &:nth-child(odd) {
        background-color: #F4F4F4;
    }

    .title {
        color: $ponal-brown;
    }

}
