.service{

  a{
    text-decoration: none;
    color: #000;
  }

}

img{
  max-width: 100%;
  display: inline-block;
  height:auto;
  vertical-align: middle;
}

video{
  max-width: 100%;
  display: inline-block;
  height: auto;
  vertical-align: middle;
}


.fa-chevron-right{
  padding-left: 15px;
}