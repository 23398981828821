/*
|--------------------------------------------------------------------------
| Top Navigation
|--------------------------------------------------------------------------
*/
#top-nav {
  border-bottom: 1px solid $lightgrey;
  padding-top: 0;
  padding-bottom: 0;

  i {
    &.cap {
      vertical-align: middle;
      line-height: inherit;
      margin-right: 8px;
    }
  }

  .navbar-collapse {
    display: flex!important;

    .navbar-nav {
      flex-direction: row;

      .nav-item {

        .nav-link {
          padding-left: 0.5rem;
          padding-right: 0.5rem;

          span {
            display: none;
          }
        }

        &.dropdown-menu {
          margin-top: 0;
          padding-top: 0;
          padding-bottom: 0;

          li {
            border-bottom: 1px solid $lightgrey;
          }

          .dropdown-menu {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
          }

          .dropdown {
            padding-top: 14px;
            padding-bottom: 14px;
            border-bottom: 1px solid $lightgrey;
            color: $white !important;

            &:hover {
              //color: $ponal-brown;
              transition: 0.3s;
              background-color: #575757;
            }

            &:last-of-type {
              border-bottom: none;
            }

          }



        }
      }
    }
  }


  @media (min-width: 768px) {

    .navbar-collapse {

      .navbar-nav {

        .nav-item {

          .nav-link {
            padding: 1rem;
            border-left: 1px solid $lightgrey;

            i {
              margin-right: 0.25rem;
            }

            span {
              display: inline;
            }
          }

          &:last-of-type {
            .nav-link {
              border-right: 1px solid $lightgrey;
            }
          }
        }
      }

    }

  }
}


.navbar-toggler {
    float: right;
    background-color: $ponal-brown;
    i {
        color: #fff;
    }
}
.toggler-div {
    display: flex;
    float: right;
    margin-top: 10px;
    //margin-top: 27px;
    button {
        width: 60px;
        height: 60px;
    }
}
/*
|--------------------------------------------------------------------------
| Primary Navigation
|--------------------------------------------------------------------------
*/
#main-nav {
  background-color: $ponal-brown;
  padding-top: 0;
  padding-bottom: 0;
  //min-height: 70px;
  z-index: 20!important;
  //-webkit-box-shadow: 0px 6px 11px -5px rgba(153,153,153,1);
  //-moz-box-shadow: 0px 6px 11px -5px rgba(153,153,153,1);
  //box-shadow: 0px 6px 11px -5px rgba(153,153,153,1);

  i {
    &.cap {
      vertical-align: middle;
      line-height: inherit;
      margin-right: 8px;
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Navbar Toggler
  |--------------------------------------------------------------------------
  */
  &.navbar-light {
    .navbar-toggler {
      border-color: rgba(255,255,255,1);

      &:focus {
        outline: 0 none;
      }
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }


  /*
  |--------------------------------------------------------------------------
  | Navbar
  |--------------------------------------------------------------------------
  */
  .navbar-collapse {

    .navbar-close {
      display: none;
    }

    .navbar-nav {

      .nav-item {
          &.active {
              background-color: #333;
              .nav-link {
                  color: white;
              }
          }
        .nav-link-small {
            color: #ffffff;
            display: block;
            padding: 18px;
        }
          &:hover{
              transition: 0.2s;
              background: #333;
              @media screen and (max-width:991px) {
                  //background: none !important;
                  background: $ponal-brown !important;
              }
          }
        .nav-link {
          color: #ffffff;
          font-weight: 700;
          //text-transform: uppercase;
          //height: 70px;
          //line-height: 38px;
          //padding: 16px 12px;
          padding: 18px;

          //&.active {
          //    background-color: #333;
          //    color: white;
          //}


        }
        .dropdown-item {
          cursor: pointer;
            color: $white !important;

          &:hover {
            //color: $ponal-brown;
            //transition: 0.3s;
          }
        }

        &.dropdown {
          .dropdown-menu {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;

            li {
              border-bottom: 1px solid $lightgrey;
            }

            .dropdown-item {
              padding-top: 14px;
              padding-bottom: 14px;
              border-bottom: 1px solid $lightgrey;
              color: $white !important;
              cursor: pointer;

              &:hover {
                color: $ponal-brown;
                transition: 0.3s;
              }

              &:last-of-type {
                border-bottom: none;
              }

              &.active {
                background-color: $ponal-brown;

                &:hover {
                  color: $white;
                }
              }

            }
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 992px) {

  #main-nav {

    .navbar-collapse {

      .navbar-nav {

        .nav-item {

          .mobile{
            display: none;
          }

          .dropdown-toggle {
            &:after {
              display: none;
            }
          }

          .dropdown-menu {
              a {
                  text-decoration: none;
              }
            .mobile {
              display: none;
            }
          }
        }

        li {
          position: relative;

          &:hover {
            > ul {
              display: block;
            }
          }
        }


        ul {
          position: absolute;
          top: 96%;
          left: 0;
          //min-width: 250px;
          //width: 100%;
            min-width: 100%;
            width: auto;
          display: none;
          background-color: #000000;
          color: white;


          li{
            border-top: 1px solid $white;
            font-size: 16px;
            font-weight:700;
            text-align: center;
            padding:10px !important;

            a{
              color: white;
              font-size: 16px;
              font-weight: 700;
            }

            form{
              //padding: 10px 0;
              font-size: 16px;
              font-weight:700;
              text-align: center;
            }
          }

          ul {
            position: absolute;
            top: 0;
            left: 100%;
            min-width: 250px;
            display: none;
          }
        }

        .dropdown {

          &.full {
            position: static;

            .dropdown-menu {
              left: 0;
              right: 0;
              border-top: 0 none;

              li {
                color: white;
                &.big {
                  display: block;
                  border-bottom: 0 none;
                }
              }

              .container {
                position: relative;

                .mega-menu {
                  position: absolute;
                  z-index: 999;
                  max-height: 500px;
                  overflow-y: auto;
                  overflow-x: hidden;
                  left: 15px;
                  right: 15px;
                  border-left: 1px solid $lightgrey;
                  border-right: 1px solid $lightgrey;
                  border-bottom: 1px solid $lightgrey;
                  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
                  background-color: #ffffff;
                  padding: 30px;

                  .top-reward-categories {

                    a {
                      color: inherit;
                      text-decoration: none;
                    }

                    h1 {
                      font-size: 1.2rem;

                      &:hover {
                        color: $ponal-brown;
                      }
                    }

                    img {
                      border-top: 2px solid black;
                      width: 100%;
                      height: auto;
                      max-height: 150px;
                      object-fit: cover;
                    }
                  }

                  .reward-categories {
                    padding-top: 4px;

                    h1 {
                      padding-top: 40px;
                      font-size: 1.2rem;
                      border-bottom: 1px solid black;
                    }

                    a {
                      text-decoration: none;
                      color: inherit;

                      &:hover {
                        color: $ponal-brown;
                      }
                    }
                  }

                  .product-categories {

                    h1  {
                      padding-top: 40px;
                      font-size: 1.2rem;
                    }

                    img {
                      border-top: 2px solid black;
                      width: 100%;
                      height: auto;
                      max-height: 166px;
                      object-fit: cover;
                    }

                    &:first-of-type {
                      h1 {
                        padding-top: 0;
                      }
                    }

                  }

                  .owl-carousel {
                    &.owl-hidden {
                      opacity: 1;
                    }

                    .owl-stage {
                      display: flex;
                    }
                    .owl-item {

                      img {
                        /*       width: auto;
                               height: 380px;
                               margin: 0 auto;*/
                      }
                    }
                  }


                }
              }
            }
          }
        }

      }
    }
  }
}

.dropdown-menu{
  border-radius: 0 !important;
  border: 0 !important;
  padding:0 0;
    a{
        text-decoration: none;
    }
}

.dropdown-item, .dropdownitem a {
    background-color: #333;
}
.dropdown-item:hover, .dropdown-item:focus{
  color: $white;
  text-decoration: none !important;
  background-color: $ponal-brown;
    @media (max-width: 991px) {
        background-color: $white !important;
        color: $ponal-brown !important;
    }
}

.dropdown-item a:hover, .dropdown-item a:focus{
  color: $white;
  text-decoration: none !important;
    @media (max-width: 991px) {
        background-color: $white !important;
        color: $ponal-brown !important;
    }
}

/* hover dropdown menus */
@media only screen and (max-width: 991px) {

  #main-nav {

    .navbar-collapse {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      min-width: 250px;
      z-index: 10;
      color: #fff;
      //color: black;
      //background-color: #ffffff;
      background-color: rgba(31,31,31,.9);
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;
      &.collapsing {
        height: auto !important;
        transition: all 0.2s ease;
        right: -350px;
      }
      &.show {
        transition: all 0.2s ease;
        right: 0;
      }

      .navbar-close {
        display: inherit;
        text-align: left;
        //background-color: #ffffff;
        background-color: transparent;
        border-bottom: 1px solid $lightgrey;
        box-shadow: none;
        border-radius: 0;
        color: #fff;
        text-align: right;
        padding-right: 15px;
        /*&:hover {
            background-color: $ponal-brown;
        }*/
        span {
          font-size: 1.2rem;
        }
      }

      .navbar-nav {

        .nav-item {
          transition: all 0.2s;

          .unmobile{
            display: none;
          }

          .nav-link {
              transition: all 0.2s;
              //height: 62px;
              height: auto;
            line-height: 30px;
            //color: black;
            color: #fff;
            border-bottom: 1px solid $lightgrey;

            &:hover {
              //color: $ponal-brown;
              background-color: $ponal-brown;
              transition: 0.2s;
            }
          }
        }

        .dropdown-submenu {
          position: relative;
          border-bottom: 0 none!important;

          .dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: -1px;

            .dropdown-item {
              padding-left: 36px;
              color: $white;
            }
          }
        }

        .dropdown {

          li {
            &.big {
              display: none;
            }
          }
        }
      }
    }

    .pointer-button {
        color: #212529 !important;
        font-size: 16px;
        padding: 0.25rem 1.5rem !important;
        font-weight: 400 !important;
        white-space: normal;
        word-wrap: break-word;
        @media (max-width: 991px) {
            &:hover{
                background-color: $white !important;
                color: $ponal-brown !important;
            }
        }
    }

    .nav-backdrop {
      background: rgba(22, 22, 22, 0.5) !important;
      position: fixed;
      display:none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9;
    }
  }
}

.login-popover {
  display: none;
}



//@media print, screen and (min-width: 63.75em) {
@media print, screen and (min-width: 992px) {
  .navbar ul.navbar-nav {
    position: relative;
    margin-top: 0;
    display: table;
    width: 100%;
    empty-cells: show;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;

    li.nav-item{
      font-size: 17px;
      padding: 0;
      margin: 0;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      font-weight: 700;
      width: 14.28%;

      .dropdown-class{
        display: block !important;
      }
    }

  }
}

//@media print, screen and (min-width: 63.75em){
@media print, screen and (min-width: 992px){
  .navbar ul.navbar-nav>li:not(:last-child) {
    border-right: 1px solid #fff;
  }
}

.py-4{
  padding-top: 0 !important;
}


//CAROUSEL
.owl-carousel .nav-btn{
  //height: 47px;
  position: absolute;
  //width: 26px;
  cursor: pointer;
  top: 40% !important;
  color: white;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled{
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide{
  left: 33px;
}
.owl-carousel .next-slide{
  right: 33px;
}
.owl-carousel .prev-slide:hover{
  background-position: 0px -53px;
}
.owl-carousel .next-slide:hover{
  background-position: -24px -53px;
}



ul.tabs{
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.tabs li{
  background: none;
  color: #222;
  display: inline-block;
  //padding: 10px 15px;
  cursor: pointer;
}
ul.tabs li a {
    text-decoration: none;
}

ul.tabs li.current{
  background: $dark-gray;
  color: $white;
}

.tab-content{
  //display: none;
  //background: #ededed;
  padding: 15px;
}

.tab-content.current{
  display: inherit;
}

.tab-link{
  text-align: center;
}

ul#language-select{
    cursor: default;
    list-style: none;
    //display: table;
    padding: 0;
    width: 100%;
    margin-top: 42px;

    li{
        margin: 0;
        display: table-cell;
        vertical-align: middle;
        &:not(:last-child) {
            a {
                padding-right: 10px !important;
            }
        }
        a{
            padding-left: 10px !important;
            //color: #fff !important;
            color: #575756 !important;
            &.nav-link-small {
                padding: 18px !important;
                color: #fff !important;
            }
        }
    }

    @media (max-width:991px) {
        margin-top: 0;
        padding: 0 0 !important;
        li{
            padding-left: 0 !important;
            a{
                //color: black !important;
                &:hover{
                    //color: $ponal-brown !important;
                }
            }
        }
    }
}

.country-img{
    border: 1px solid white;
}



@media (max-width: 991px) {
    .display_out{
        display: none;
    }
    #main-nav {
        .container {
            height: 0;
        }
    }
}

@media (min-width: 992px) {
    .display_out_menu{
        display: none !important;
    }
}
.backlink a {
    text-decoration: none;
}
.back {
    top: -5px;
    position: relative;
}
