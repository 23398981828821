.top{
  padding-top:40px;
}

table.download {
  font-size: 22px;
  font-weight: 300;
  //font-family: "Futura";
}

@media print, screen and (min-width: 63.75em){
  table {
    width: 100%!important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em){
  .show-for-small-only {
    display: none!important;
  }
}


table.download thead {
  background: 0 0;
  color: #333;
}

table.download thead th {
  border-bottom: 1px solid #333;
}

table tfoot td, table tfoot th, table thead td, table thead th {
  padding: .5rem 1.25rem .625rem;
  font-weight: 700;
  text-align: left;
}

table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid rgba(13,13,13,0);
  background-color: transparent;
}

table tbody td {
  border-bottom: 1px solid #333;
}

table tbody td, table tbody th {
  padding: .5rem 1.25rem .625rem;
}

table.download td {
  font-weight: 200;
}

table tbody td.vm {
  vertical-align: middle;
}

.text-right {
  text-align: right;
}

.button.button-download {
  padding: 8px 12px;
  background: $ponal-brown;
  background-size: 20px 17px;
  font-size: 16px;
  color: white;
  margin: 0;
  font-weight: 700;
  .fa-download{
    padding-right: 15px;
  }
}

.button.button-download-zip {
  padding: 8px 15px 8px 20px;
  background: $ponal-brown;
  background-size: 20px 17px;
  font-size: 16px;
  color: white;
  margin: 0;
  font-weight: 700;
  .fa-download{
    padding-right: 15px;
  }
}

#button-email-zip{
  padding: 8px 15px 8px 20px;
  background: $white;
  background-size: 20px 17px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  cursor: pointer;
  font-size: 16px;
  color: $ponal-brown;
  margin: 0;
  font-weight: 700;
  .fa-at,.fa-border{
    //padding-right: 15px;
    color: #fff !important;
    padding: 6px;
    margin-top: -5px;
    background: $ponal-brown;
  }
  &:hover{
    color: #575757;
  }
}

.button-unselect{
  padding: 4px 10px 4px 10px;
  background: $ponal-brown;
  background-size: 20px 17px;
  color: white;
  margin: -5px 10px 2px 0;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
}

#button-unselect{
  padding: 8px 15px 8px 20px;
  background: $white;
  background-size: 20px 17px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  cursor: pointer;
  font-size: 16px;
  color: $ponal-brown;
  margin: 0;
  font-weight: 700;

  .fa-sqaure{
    background: black;
    width: 35px;
    height: 35px;
    margin: -10px 10px 0px 0px;
    position: relative;
    top: -43%;
  }

  .fa-times{
    vertical-align: middle;
  }

  &>.button-unselect:hover{
    background: red !important;
  }

  &:hover{
    color: #575757 !important;
    &>.button-unselect{
      background: #575757 !important;
    }
  }
}

i.fa-sqaure{
  background: black;
  width: 35px;
  height: 35px;
  margin: -10px 10px 0px 0px;
  position: relative;
  top: -43%;
}

.download-clear{
  color: #000;
  display: inline-block;
  height: 34px;
  margin-left: 15px;
  vertical-align: middle;
  cursor: pointer;

  i.fa-times{
    color: white !important;
  }
}

.download-select {
  display: inline-block;
  border: 2px solid $dark-gray;
  width: 34px;
  height: 34px;
  margin-left: 15px;
  vertical-align: middle;
}

.download-multi {
  margin: 40px 0;
  border-bottom: 1px solid #343434;
  border-top: 1px solid #343434;
  padding: 14px 0 8px 0;

  .fa-download, .fa-at{
    padding-right: 10px;
  }

}

.download-multi a.downloads-clear, .download-multi a.downloads-email {
  position: relative;
  top: -10px;
  font-weight: 700;
  font-size: 16px;
}

table tbody td.align_right {
  text-align: right;
}

table tbody td.vm {
  vertical-align: middle;
}

@media screen and (max-width: 39.9375em){
  table.download-stack td:first-child {
    padding: 15px 15px 0;
  }
}

@media screen and (max-width: 39.9375em){
  table.download tbody td {
    border-bottom: none;
  }
}

@media screen and (max-width: 39.9375em){
  table.download-stack {
    display: block;
    width: 100%;
  }

  table.download-stack tbody {
    border-top: 1px solid #000!important;
  }

  table.download-stack tbody, table.download-stack tr {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 39.9375em){
  table.download-stack td {
    display: block;
    width: 100%;
    border: 0;
  }

  table.download-stack tr {
    border-bottom: 1px solid #000!important;
  }
}

@media screen and (max-width: 63.6875em){
  .show-for-large {
    display: none!important;
  }

}

@media screen and (max-width: 39.9375em){
  .show-for-medium {
    display: none!important;
  }
}

@media screen and (max-width: 39.9375em){
  table.download-stack th {
    display: none;
  }

  table.download-stack a.download-select {
    float: right;
  }
}


@media screen and (max-width: 63.6875em){
  .mobile-left {
    text-align: left!important;
  }
}

@media screen and (max-width: 39.9375em){
  .hide-for-small-only {
    display: none!important;
  }
}

@media screen and (max-width: 39.9375em){

  table.download tbody td.button-wrap {
    border-bottom: 1px solid #343434;
  }

  table.download tbody td.button-wrap .button.button-download {
    width: 100%;
    background-image: none;
  }

  .hide-for-small-only {
    display: none!important;
  }
}


.inactive{
  //cursor: not-allowed !important;
  //pointer-events: none;
}

a#button-unselect, .button-unselect{
  .inactive{
    background: #848484;
    pointer-events: none;
  }
}

.download-clear{
  &.inactive{
    color: #848484 !important;
    pointer-events: none;
  }
}

.button.button-download-zip.inactive{
    background: #848484 !important;
    cursor: not-allowed;
    pointer-events: none;
  }

i.fa-border{
  border: solid 0.08em $ponal-brown;
  border-radius: 0;
}


#button-email-zip{
  &.inactive{
    color: #848484 !important;
    cursor: not-allowed;
    pointer-events: none;

      &>.fa-border{
          background: #848484;
          border:solid 0.08em #848484;
      }

  }
}
#button-email-zip:hover {
    .fa-at {
        background-color: #575757 !important;
    }
}
.parent-download-zip{
  &.inactive {
    cursor: not-allowed;
  }
}

.parent-email-zip{
  &.inactive {
    cursor: not-allowed;
  }
}

.parent-unselect{
  &.inactive {
    cursor: not-allowed;
  }
}
